<template>
  <div class="about">
    <Header title="О программе" />
    
    <div class="wrapper">
      Ninja Pizza v1.4.1
      (с) 2020 <span v-if="new Date().getFullYear() > 2020">- {{ new Date().getFullYear() }}</span>
      <img src="@/assets/img/logo.png" alt="" class="about__logo">
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'About',
  
  components: {
    Header
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  margin: 32px auto;
  padding: 0 20px;
  background-color: #fff;
}
.container {
  margin: 0 10px;
}
.about {
  min-height: 100vh;
  background-color: #fff;
  &__logo {
    margin: 100px auto 0;
    width: 100px;
  }
}
</style>
